import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import WorkoutCard from "../components/workout-card"
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Pagination } from 'swiper/core';
import 'swiper/swiper.scss';
import MenuIcon from '../components/icons/menu';
import Logo from '../components/icons/logo';
import ScreenWithSideNav from '../components/common/screens/screen-with-side-nav';
import Layout from '../components/layout';


const IndexPage = (props) => {
  return (
    <Layout>
      <h1 className="pt-8 mx-4 text-center">We build<br/>digital tools<br/>for personal trainers</h1>
      <h3 className="text-center">Our first product</h3>
      <div className="text-center">
        <Link to="/workout-builder" className="btn">Free Workout Builder</Link>
      </div>
    </Layout>
  )
}

export default IndexPage

// export const query = graphql`
// query {
//   allWorkout {
//     nodes {
//       id
//       title,
//       level,
//       trainer {
//         firstName
//       }
//       localImage {
//         childImageSharp {
//           gatsbyImageData(layout: FULL_WIDTH)
//         }
//       }
//     }
//   }
// }
// `